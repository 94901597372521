import React from "react";
import {
  FaLinkedin,
  FaGithubSquare,
  FaTwitterSquare,
  FaStackOverflow,
  FaDev,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="mx-auto grid max-w-[1240px] gap-8 px-4 py-16 text-gray-300 lg:grid-cols-3">
      <div>
        <a href="#">
          <h1 className="w-full text-3xl font-bold text-[#00df9a]">REACT.</h1>
        </a>
        <p className="py-4">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Id odit
          ullam iste repellat consequatur libero reiciendis, blanditiis
          accusantium.
        </p>
        <div className="my-6 flex justify-between md:w-[75%]">
          <a href="https://www.linkedin.com/in/1diazdev/">
            <FaLinkedin size={30} />
          </a>{" "}
          <a href="https://twitter.com/1diazdev">
            <FaTwitterSquare size={30} />
          </a>{" "}
          <a href="https://github.com/JuanPabloDiaz">
            <FaGithubSquare size={30} />
          </a>{" "}
          <a href="https://stackoverflow.com/users/9374650/juan-diaz">
            <FaStackOverflow size={30} />
          </a>{" "}
          <a href="https://dev.to/1diazdev">
            <FaDev size={30} />
          </a>
        </div>
      </div>
      <div className="mt-6 flex justify-between lg:col-span-2">
        <div>
          <h6 className="font-medium text-gray-400">Solutions</h6>
          <ul>
            <li className="py-2 text-sm">Analytics</li>
            <li className="py-2 text-sm">Marketing</li>
            <li className="py-2 text-sm">Commerce</li>
            <li className="py-2 text-sm">Insights</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Support</h6>
          <ul>
            <li className="py-2 text-sm">Pricing</li>
            <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Company</h6>
          <ul>
            <li className="py-2 text-sm">About</li>
            <li className="py-2 text-sm">Blog</li>
            <li className="py-2 text-sm">Jobs</li>
            <li className="py-2 text-sm">Press</li>
            <li className="py-2 text-sm">Careers</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Legal</h6>
          <ul>
            <li className="py-2 text-sm">Claim</li>
            <li className="py-2 text-sm">Policy</li>
            <li className="py-2 text-sm">Terms</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
