// Steps to import local images to a React component and loop through them:
// https://www.shecodes.io/athena/38787-how-to-import-and-loop-through-local-images-in-a-react-component

// Card component Source example:
// https://www.floatui.com/components/team-sections

import React from "react";
import Single from "../assets/single.png";
import Double from "../assets/double.png";
import Triple from "../assets/triple.png";

export default () => {
  const cardsItems = [
    {
      icon: Single,
      title: "Single User",
      price: "$149",
      space: "200 GB Storage",
      permision: "1 Granted User",
      send: "Send up to 2 GB",
    },
    {
      icon: Double,
      title: "Double User",
      price: "$199",
      space: "500 GB Storage",
      permision: "2 Granted User",
      send: "Send up to 3 GB",
    },
    {
      icon: Triple,
      title: "Triple User",
      price: "$249",
      space: "Unlimited Storage",
      permision: "5 Granted User",
      send: "Send up to 6 GB",
    },
  ];

  return (
    <section name="price" className="w-full bg-white px-4 py-[10rem]">
      <div className="mx-auto grid max-w-[1240px] gap-8 md:grid-cols-3">
        {cardsItems.map((item, index) => (
          <div
            key={index}
            className="my-4 flex w-full flex-col rounded-lg p-4 shadow-xl duration-300 hover:scale-105"
          >
            <img
              className="mx-auto mt-[-3rem] w-20 bg-white"
              src={item.icon}
              alt="/"
            />
            <h2 className="py-8 text-center text-2xl font-bold">
              {item.title}
            </h2>
            <p className="text-center text-4xl font-bold">{item.price}</p>
            <div className="text-center font-medium">
              <p className="mx-8 mt-8 border-b py-2">{item.space}</p>
              <p className="mx-8 border-b py-2">{item.permision}</p>
              <p className="mx-8 border-b py-2">{item.send}</p>
            </div>
            <button className="mx-auto my-6 w-[200px] rounded-md bg-[#00df9a] px-6 py-3 font-medium">
              Start Trial
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};
