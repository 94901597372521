import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-scroll";

// Navbar component that will be used in the landing page
// Function to hide and show the sidebar menu,
const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  //   this function is used to handle the menu icon
  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="mx-auto flex h-24 max-w-[1240px] items-center justify-between text-white">
      <h1 className="w-full text-3xl font-bold text-[#00df9a]">REACT.</h1>
      <ul className="hidden md:flex">
        <li className="p-4">
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="p-4">
          <Link to="analytics" smooth={true} duration={500}>
            Analytics
          </Link>
        </li>
        <li className="p-4">
          <Link to="price" smooth={true} duration={500}>
            Price
          </Link>
        </li>
        <li className="p-4">
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="p-4">
          <Link to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* this is the sidebar menu icon that will be shown on mobile devices */}
      {/* in tailwind use the hidden and block classes respectively */}
      <div onClick={handleMenu} className="block md:hidden">
        {showMenu ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      {/* this is the menu that will be shown on mobile devices */}
      {/* this showMenu hides and shows the menu: */}
      <div
        className={
          showMenu
            ? "fixed left-0 top-0 h-full w-[45%] border-r border-r-gray-900 bg-[#000300] duration-500 ease-in-out"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="m-4 w-full text-3xl font-bold text-[#00df9a]">REACT.</h1>
        <ul className="p-4 uppercase">
          <li className="border-b border-gray-600 p-4">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="border-b border-gray-600 p-4">
            <Link to="analytics" smooth={true} duration={500}>
              Analytics
            </Link>
          </li>
          <li className="border-b border-gray-600 p-4">
            <Link to="price" smooth={true} duration={500}>
              Price
            </Link>
          </li>
          <li className="border-b border-gray-600 p-4">
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className="p-4">
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
